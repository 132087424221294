import React from 'react';
import { Link } from 'gatsby';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Clouds, AppStoreButton } from '../../..';
import styles from './Footer.module.scss';

interface Props {}

const SectionFooter: React.FC<Props> = ({}) => {
  const { t } = useTranslation();

  return (
    <>
      <section className={`${styles.container} parallax`}>
        <div className={`content ${styles.bottomInner}`}>
          <Row>
            <Col md={8} xs={12} className={styles.nav}>
              <h3>{t('common.appName')}</h3>
              <ul>
                <li>
                  <Link to="/terms">{t('common.termsOfService')}</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">{t('common.PrivacyPolicy')}</Link>
                </li>
                <li>
                  <a href="https://forms.gle/32tLrUoEFZxiZx956" target="_blank">
                    {t('common.contact')}
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={8} xs={12} className={styles.nav}>
              <h3>{t('common.social')}</h3>
              <ul>
                <li>
                  <a href="https://twitter.com/pany_jp">{t('common.twitter')}</a>
                </li>
              </ul>
            </Col>
            <Col md={8} xs={24} className={styles.nav}>
              <div className={styles.appStores}>
                <AppStoreButton storeType="android" className={styles.btnAppstore} />
                <AppStoreButton storeType="ios" className={styles.btnAppstore} />
              </div>
            </Col>
          </Row>
        </div>
        <Clouds n={[3, 2]} />
      </section>
    </>
  );
};

export default SectionFooter;
