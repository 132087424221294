import React from 'react';
import styles from './Footer.module.scss';
import { Layout } from 'antd';

interface Props {}

const Footer: React.FC<Props> = ({}) => (
  <Layout.Footer className={styles.footer}>
    <div className={`content`}>
      <p>&copy; {new Date().getFullYear()} Pany</p>
    </div>
  </Layout.Footer>
);

export default Footer;
