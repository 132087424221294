import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

export class Firebase {
  private static instance: Firebase;
  public app: firebase.app.App;
  public auth: firebase.auth.Auth;
  public db: firebase.firestore.Firestore;
  public functions: firebase.functions.Functions;

  private constructor() {}

  private isDev(): boolean {
    const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';
    return activeEnv === 'development';
  }

  private async getConfig() {
    if (this.isDev()) {
      return {
        apiKey: process.env.GATSBY_FIREBASE_API_KEY,
        authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
        projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
        storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.GATSBY_FIREBASE_APP_ID,
        measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
      };
    } else {
      // Firebase Hosting
      return await fetch('/__/firebase/init.json').then(response => response.json());
    }
  }

  private async init() {
    const config = await this.getConfig();
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
  }

  static async getInstance() {
    if (!this.instance) {
      this.instance = new Firebase();
      await this.instance.init();
    }

    return this.instance;
  }
}
