import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import styles from './Terms.module.scss';

interface Props {}

const Terms: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { Title, Paragraph, Text } = Typography;

  const SUPPORT_URL = `https://pany.me/support`;

  return (
    <div className={`content ${styles.container}`}>
      <Title>サービス利用規約</Title>
      <Paragraph>発効日：2020年2月18日</Paragraph>

      <Paragraph>
        <span className={styles.appName}>{t('common.appName')}</span>
        （以下「本サービス」と総称）を使用するためには、本規約およびプライバシーポリシーを受諾する必要があります。本規約およびプライバシーポリシーは、(i)
        最初にアプリを開いた時、および (ii)
        本規約またはプライバシーポリシーの内容に重大な変更があった場合にお客様に表示されます。本規約およびプライバシーポリシーを受諾されない場合は、本サービスの使用をご遠慮ください。
      </Paragraph>

      <Title level={2}>1. 本サービスの利用条件</Title>

      <Paragraph>本サービスの利用により、お客様は、以下がいずれも正しいことを保証したものとみなされます。</Paragraph>

      <ul>
        <li>18歳以上であること。</li>
        <li>当社と有効な契約を締結できること。</li>
        <li>関連する国・地域の法律のもとで本サービスの利用を禁じられた者でないこと。</li>
        <li>性犯罪者として有罪判決を受けていないこと。</li>
        <li>本規約および適用される地方、州、国および国際的な法律、規則、規定のすべてに従うこと。</li>
      </ul>

      <Title level={2}>2. お客様に付与される権利</Title>

      <Paragraph>
        当社が提供するすべてのソフトウェアは、アップグレード、アップデート、その他の新しい機能を自動的にダウンロードし、
        インストールを行う可能性があります。お客様は、デバイスの設定を通じて、このような自動ダウンロードの設定をすることができます。
      </Paragraph>

      <Paragraph>
        本サービスの一部でも複製、修正、配布、販売もしくは賃貸することまたはリバースエンジニアリングや当該ソフトウェアのソースコードを抽出しようとすることは禁止されています。
        ただし、かかる禁止が法令によって認められない場合または当社からの書面による承諾がある場合はその限りではありません。
      </Paragraph>

      <Title level={2}>3. お客様が当社に付与する権利</Title>

      <Paragraph>
        本サービスにおいて、お客様は、コンテンツの作成やアップロード、投稿、送信、受信、保存をすることとなります。
        こうした操作を行った場合であっても、コンテンツについてのお客様の権利は、そのままお客様に帰属するものとします。
        ただし、お客様は当該コンテンツの使用ライセンスを当社に付与するものとします。
        ライセンスの範囲は、お客様がどの本サービスを使用するか、またどのような設定をしているかによって異なります。
      </Paragraph>

      <Paragraph>
        お客様のコンテンツの閲覧者に関する管理方法の詳細については、当社の
        <a href="/privacy-policy">プライバシーポリシー</a>をご覧ください。
      </Paragraph>

      <Paragraph>
        当社は、(i) 本規約に違反すると考えられるコンテンツを削除する権利、および (ii)
        法的義務を遵守する必要が生じた場合には、いかなるコンテンツをも削除する権利を有します。
        ただし、お客様が本サービスを通して作成、アップロード、投稿、送信、または保存したコンテンツに関する責任は、お客様が単独で負うものとします。
      </Paragraph>

      <Paragraph>
        本サービスには広告が含まれる場合があります。お客様は、本サービスにアクセス、利用する代わりに、
        当社および第三者のビジネスパートナーが、本サービスに広告を掲載することに同意するものとします。
        これには、お客様が当社に提供する個人情報、または当社が収集したお客様の個人情報に基づいたパーソナライズ広告も含まれます。
        本サービスには、お客様や他のユーザーが提供するコンテンツが含まれるため、
        お客様のコンテンツの近く、コンテンツ間、コンテンツ上またはコンテンツ内に広告が表示される場合があります。
      </Paragraph>

      <Paragraph>
        当社は、お客様からのご意見を受け付けています。ただし、いただいたご意見やご提案は、
        いかなる制限もお客様への義務もなく当社が利用する場合があること、
        また、その場合にお客様に報酬が支払われることがない点をあらかじめご了承ください。
      </Paragraph>

      <Title level={2}>4. 他のユーザーのコンテンツ</Title>

      <Paragraph>
        当社の本サービスのコンテンツの大部分は、ユーザー、その他の第三者によって作成されています。
        コンテンツが公的に投稿されたものであるか私的に送信されたものであるかによらず、コンテンツに関する責任は、送信した個人または組織自身が負います。
        当社は、本サービスで掲載されるすべてのコンテンツを確認し、本規約、または法律に違反するコンテンツを削除する権利を有しますが、
        必ずしもすべてのコンテンツを確認するものではありません。
      </Paragraph>

      <Paragraph>
        本規約において、当社は、本サービスがユーザーによって悪用されることを望まない旨、明言しています。
        ただし、当社は、すべてのコンテンツを確認するわけではないため、
        本サービスのコンテンツまたは当社のユーザーの本サービスの使用が常に本規約に従っていると保証することはできません。
      </Paragraph>

      <Title level={2}>5. プライバシー</Title>

      <Paragraph>
        当社では、お客様のプライバシーを重視しています。サービスを使用する際の個人情報の取り扱いについては、
        <a href="/privacy-policy">プライバシーポリシー</a>をご覧ください。
      </Paragraph>

      <Title level={2}>6. 他者の権利の尊重</Title>

      <Paragraph>
        当社は、他者の権利を尊重します。お客様にも他者の権利を尊重していただきます。
        以下に該当する目的で、本サービスを使用すること、または、他者による本サービスの使用を手助けすることは禁止されます。
      </Paragraph>

      <ul>
        <li>
          他者のパブリシティ権、プライバシー権、著作権、商標権、またはその他の知的財産権に違反したり、これらの権利を侵害すること。
        </li>
        <li>いじめ、嫌がらせ、脅迫。</li>
        <li>中傷。</li>
        <li>スパムまたは当社のユーザーの勧誘。</li>
      </ul>

      <Paragraph>
        お客様には当社の権利を尊重していただく必要があります。
        以下に該当する行為（または他の人の以下に該当する行為を手助けすること）は禁じられています。
      </Paragraph>

      <ul>
        <li>
          当社が発行したガイドラインで明示的に許可されている場合を除き、ブランディング、ロゴ、アイコン、ユーザーインターフェース、デザイン、写真、動画、
          その他当社のサービスに使用されている素材を使用すること。
        </li>
        <li>当社の著作権、商標、またはその他の知的財産権に違反したり、これらの権利を侵害すること。</li>
        <li>
          本規約に定められた場合を除いた、本サービスまたは本サービスのコンテンツのいかなる部分でも
          コピー、アーカイブ化、ダウンロード、アップロード、配布、配給、放送、実演、表示、提供、その他の方法で利用すること。
        </li>
        <li>
          当社の同意なく、本サービス、本サービスが提供するツール、または本サービスのコンテンツを、商業目的で利用すること。
        </li>
      </ul>

      <Paragraph>
        お客様は、本規約で認められている以外の方法により本サービスや本サービスのコンテンツを利用することはできません。
        また、他者のそうした行為を手助けすることもできません。
      </Paragraph>

      <Title level={2}>7. 著作権の尊重</Title>

      <Paragraph>
        当社は、米国デジタルミレニアム著作権法を含む著作権法を遵守します。
        そのため、権利を侵害するコンテンツを発見した場合、これを迅速に本サービスから削除するために合理的な措置を講じます。
        当社は、著作権違反を頻繁に繰り返すユーザーがいることを把握した場合、権限の範囲内で当該ユーザーのアカウントを閉鎖する措置を取ります。
      </Paragraph>

      <Title level={2}>8. 安全性</Title>

      <Paragraph>
        当社は、本サービスがすべての<span className={styles.appName}>{t('common.appName')}</span>
        ユーザーにとって安全な場となるように配慮をしています。
        しかしながら、このような安全性を、当社において保証することはできないため、
        お客様にも以下の項目につき、ご協力いただけますようお願いいたします。
        なお、お客様は、本サービスを利用することで、以下の内容につき同意するものとします。
      </Paragraph>

      <ul>
        <li>不正な目的または本規約で禁止されている目的のために本サービスを利用しないこと。 </li>
        <li>
          ロボット、スパイダー、クローラー、スクレーパー、その他の自動的な方法またはインターフェイスを使用して本サービスにアクセスしたり、
          他のユーザーの情報を抽出したりしないこと。
        </li>
        <li>
          書面による同意を得ずに、本サービスまたは他のユーザーのコンテンツや情報を操作する第三者アプリケーションを利用または開発しないこと。
        </li>
        <li>
          他のユーザーが本サービスを活用するのを妨害したり、混乱を与えたり、悪影響を及ぼしたり、阻止したりするおそれのある方法で本サービスを利用しないこと。
          また、方法を問わず本サービスの機能を損なったり、無効にしたり、過負荷をかけたり、害したりするおそれのある方法で本サービスを利用しないこと。
        </li>
        <li>許可を得ずに、他のユーザーのアカウント、ユーザーIDまたはパスワードを使用しまたは使用を試みないこと。</li>
        <li>他のユーザーからログイン情報を聞き出さないこと。 </li>
        <li>ポルノ、生々しい暴力、脅迫、ヘイトスピーチ、暴力を誘発する内容を含むコンテンツを投稿しないこと。 </li>
        <li>ウイルスその他の悪意のあるコードをアップロードするなどして本サービスの安全性を脅かさないこと。 </li>
        <li>
          当社が使用しているコンテンツフィルタリング技術の回避および自分がアクセスする権限を持たない本サービスのエリアまたは機能へのアクセスを試みないこと。
        </li>
        <li>当社の本サービス、システムまたはネットワークの脆弱性を探索、精査、検査しないこと。</li>
        <li>本規約に違反する行為を促さず推奨しないこと。</li>
      </ul>

      <Title level={2}>9. お客様のアカウント</Title>

      <Paragraph>
        お客様は、ご自身の<span className={styles.appName}>{t('common.appName')}</span>
        アカウントで行われるすべての操作につき責任を負うものとします。
        そのため、ご自身のアカウントを安全に保つことが重要です。
        ご自身が利用する他のアカウントでは使用していない強固なパスワードを作成するのが、アカウントの安全性を保つための効果的な方法の一つです。
      </Paragraph>

      <Paragraph>
        お客様が、本サービスを利用する際には、常識的にご利用いただけますことをお願いするほか、以下に同意していただきます。
      </Paragraph>

      <ul>
        <li>ご自身用に複数のアカウントを作成しないこと。</li>
        <li>
          当社の書面による許可を得ない限り、当社がお客様のアカウントを無効にした後に別のアカウントを作成しないこと。
        </li>
        <li>
          書面による許可を得ずに、自分の<span className={styles.appName}>{t('common.appName')}</span>アカウント、
          <span className={styles.appName}>{t('common.appName')}</span>
          ユーザーIDを購入、販売、賃借、賃貸しないこと。
        </li>
        <li>パスワードを他人に教えないこと。 </li>
        <li>不正な第三者アプリケーションまたはクライアントを使用して本サービスにログイン・アクセスを試みないこと。</li>
      </ul>

      <Paragraph>
        お客様のアカウントが、他人からの不正アクセス被害にあったことに気付いた場合、ただちに
        <a href={SUPPORT_URL} target="_blank">
          サポート
        </a>
        にお知らせください。
      </Paragraph>

      <Title level={2}>10. データ課金と携帯電話</Title>

      <Paragraph>
        テキストメッセージ料金やデータ料金など、本サービスを利用して発生する携帯電話料金の支払いについては、お客様のご負担となります。
        これらの料金について、不明瞭な点がある場合、本サービスを利用する前に携帯電話会社にご確認ください。
      </Paragraph>

      <Paragraph>
        <span className={styles.appName}>{t('common.appName')}</span>
        アカウントの作成に使用した携帯電話番号を変更または解約した場合、お客様宛のメッセージがほかの方に送信されることがないよう、
        変更または解約から72時間以内に、「設定」においてアカウント情報をアップデートされますようお願いいたします。
      </Paragraph>

      <Title level={2}>11. 外部サービス</Title>

      <Paragraph>
        第三者が管理し、本サービスを通じて提供される外部サービスまたは機能（当社が第三者と共同で提供する本サービスを含みます）をお客様が利用する場合、
        第三者とお客様との関係については第三者の規約に準拠するものとします。
        当社は第三者の規約または第三者の規約に沿って行われる行動について責任を負わないものとします。
      </Paragraph>

      <Title level={2}>12. 本サービスの修正と終了</Title>

      <Paragraph>
        当社は、常に、既存の本サービスの改善を行い、新しいサービスを生み出しており、
        このため、商品、機能の追加や削除を行う可能性や、本サービス自体の停止や中止を行う可能性があります。
        当社は、常に上記のような措置を取る可能性があります。その際にはできるだけ事前に通知を行うように努めますが、
        事前に通知を行うことができないことがあります。
      </Paragraph>

      <Paragraph>
        お客様には末永く<span className={styles.appName}>{t('common.appName')}</span>
        をご利用いただきたいと願っておりますが、お客様において、アカウントを削除することで、いつでも
        <span className={styles.appName}>{t('common.appName')}</span>との契約を解除することができます。
      </Paragraph>

      <Paragraph>
        当社もまた、お客様が本規約、その他当社が発行するガイドライン、
        もしくは法律に違反した場合、または当社の管理の及ばない何らかの理由によって、お客様との契約を解除することができます。
        その際にはできる限り事前に通知するように努めますが、その保証はできません。
        当社がお客様との契約を解除する権利によって、本サービスのすべての提供を停止したり、本サービスの利用に新しい制限や追加の制限を課すことができます。
        例えば、いつでも、理由の有無にかかわらず、長期間利用のないアカウントを無効にしたり、お客様のユーザーIDを撤回したりすることができます。
      </Paragraph>

      <Paragraph>
        お客様と当社のいずれが契約を解除したかに関わらず、お客様と当社は、
        双方ともに引き続き本規約の第3項、第6項、第9項、第12項から最終項に拘束されるものとします。
      </Paragraph>

      <Title level={2}>13. 補償</Title>

      <Paragraph>
        お客様は、いかなる方法であっても、法令の範囲内で、 （a）本サービスへのアクセスまたは本サービスの利用、
        （b）お客様のコンテンツ、および
        （c）本規約の違反に起因または関連したあらゆる苦情、告発、請求、損害、損失、費用、責任、負担（弁護士費用を含む）につき、
        当社およびその関連会社、取締役、オフィサー、株主、従業員、ライセンサー、サプライヤー、代理人に対して補償し、
        これらを防御し、これらに損害を与えないことに同意するものとします。
        お客様が、企業その他の法人を代表して本規約に同意する場合、この補償義務は当該企業または法人に適用されます。
      </Paragraph>

      <Title level={2}>14. 免責事項</Title>

      <Paragraph>
        当社は、快適かつ継続的に本サービスが提供されるように務めていますが、常にそのように本サービスをご提供できることをお約束することはできません。
      </Paragraph>

      <Paragraph>
        <strong>
          本サービスは、法令によって認められる範囲内で、明示的か黙示的かを問わず、具体的には、黙示の保証、条件、または、(i)
          商品性、品質、特定の目的への適合性、権原の存在、平穏の享有、第三者に対する侵害の不存在に関する他の条件、または、(ii)
          取引の過程において発生するものに関する他の条件を含む、いかなる保証も付されることなく、「現状有姿」および「提供可能な限度」で、
          提供されるものとします。また、当社は、素晴らしいユーザーエクスペリエンスを提供できるよう努めますが、
          これは（a）本サービスが常に安全であり、エラーがなく、最新であること、
          （b）本サービスが常に遅延、途絶または欠陥なく機能すること、
          （c）お客様が本サービス経由で取得するコンテンツまたは情報が最新で正確であることを表明または保証するものではありません。
        </strong>
      </Paragraph>

      <Paragraph>
        <strong>
          お客様のお住まいの国の法律で、本条項に記載されている賠償責任の除外が認められない場合、それらの除外は適用されないものとします。
        </strong>
      </Paragraph>

      <Paragraph>
        <strong>
          当社およびその関連会社は、本サービスにおいてまたは本サービスを通じて、
          お客様、別のユーザーまたは第三者が作成、アップロード、投稿、送信、受信または保存したいかなるコンテンツについて一切の責任を負いません。
          お客様は、攻撃的、違法または誤解を招くなどの不適切なコンテンツに接する可能性があること、
          また、そのいずれについても当社およびその関連会社は責任を負わないことを了承し、これに同意するものとします。
        </strong>
      </Paragraph>

      <Paragraph>
        <strong>
          お住いの国の法律で要求されている場合は、本規約のいかなる条項も、コンテンツの削除に伴う当社の責任を除外・制限できないものとします。
        </strong>
      </Paragraph>

      <Title level={2}>15. 責任の制限</Title>

      <Paragraph>
        <strong>
          (a) 本サービスの使用もしくは使用不能、 (b) 本サービスへのアクセスもしくはアクセス不能、 (c)
          本サービス上での、もしくは、本サービスを介した、他のユーザーもしくは第三者の行為やコンテンツ、または (d)
          お客様のコンテンツへの不正アクセス、不正使用もしくは不正改変の、いずれかによって発生する間接的損害、付随的損害、特別損害、
          後続損害、懲罰賠償もしくは重畳的懲罰賠償、または、直接的もしくは間接的に生じた収益もしくは収入の逸失、データ、使用権、営業権、
          その他の無形の損失のいずれについても、当社およびその関連会社、取締役、オフィサー、株主、従業員、ライセンサー、
          代理人は、一切の責任を負わないものとします。いかなる場合でも、本サービスに関連するすべての請求について
          当社またはその関連会社が負担する賠償責任の合計額は、お客様が過去12か月間に、
          有料の本サービスについて当社に対して支払った金額を上限とします。
        </strong>
      </Paragraph>

      <Paragraph>
        <strong>
          お客様のお住まいの国の法律で、本条項に記載されている賠償責任の制限が認められない場合、それらの制限は適用されないものとします。
        </strong>
      </Paragraph>

      <Title level={2}>16. 紛争解決、仲裁</Title>

      <Paragraph>
        懸念事項がある場合には、ご相談ください。まずは、当社まで
        <a href={SUPPORT_URL} target="_blank">
          お問い合わせ
        </a>
        ください。問題解決に向けて、できる限りの努力をいたします。
      </Paragraph>

      <Paragraph>
        本サービスの一部には、本サービスまたはお客様の居住地に特有の紛争解決条項を含む追加規約が適用される場合があります。
      </Paragraph>

      <Title level={2}>17. 合意管轄、準拠法</Title>

      <Paragraph>
        本規約において認められている範囲で、当事者は法廷で訴訟をすることができます。その場合には、お客様と当社は、
        本規約または本サービスの利用に起因または関連するすべての請求と紛争（契約上のものであるか否かを問わず）において、
        お客様の居住国で禁止されている場合を除き、日本の裁判所を専属的合意管轄裁判所とすることに合意します。
        お客様と当社は、それら裁判所の専属管轄権に同意します。
        本規約、ならびに本規約およびその対象とする事項に起因または関連するすべての請求と紛争（契約上のものであるか否かを問わず）には、
        日本法が適用されます。
      </Paragraph>

      <Title level={2}>18. 分離独立性</Title>

      <Paragraph>
        本規約のいずれかの条項に法的強制力がないとみなされた場合、
        その条項は本規約から分離され、残りの条項の有効性および法的強制力には影響を与えないものとします。
      </Paragraph>

      <Title level={2}>19.特定の本サービスに関する追加規約</Title>

      <Paragraph>
        当社の本サービスは多岐に渡るため、特定の本サービスに関して追加規約を定める必要が生じることがあります。
        そのような本サービスを利用する場合、当該本サービスへのアクセスの前に追加規約が表示され、
        それを受諾した時点で、お客様と当社との契約に追加規約も含まれることになります。
        追加規約のいかなる部分も、本規約と矛盾する場合には、追加規約が優先するものとします。
      </Paragraph>

      <Title level={2}>20. 最後に</Title>

      <ul>
        <li>本規約は、お客様と当社との間の完全な合意を構成するものとし、従前のすべての契約に優先するものとします。</li>
        <li>本規約により、第三者の受益権が発生すること、または付与されることはありません。</li>
        <li>当社が、本規約の規定に基づく権利を行使しない場合でも、権利放棄とみなされないものとします。</li>
        <li>明示的にお客様に付与された権利以外のすべての権利は、当社が保持します。</li>
        <li>当社の合意なく、本規約から生じるいかなる権利または義務は、第三者に譲渡することはできません。</li>
      </ul>
    </div>
  );
};

export default Terms;
