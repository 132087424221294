import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Layout as AntdLayout } from 'antd';
import { Head } from '../..';
import styles from './Layout.module.scss';

interface HeaderData {
  site: {
    siteMetadata: {
      title: string;
    };
  };
}

const Layout: React.FC = ({ children }) => {
  const data: HeaderData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <AntdLayout className={styles.layout}>
      <Head title={data.site.siteMetadata.title} />
      {children}
    </AntdLayout>
  );
};

export default Layout;
