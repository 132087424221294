import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img, { GatsbyImageProps, FluidObject } from 'gatsby-image';

interface ImageProps extends GatsbyImageProps {
  filename: string;
}

interface Query {
  images: {
    edges: {
      node: {
        relativePath: string;
        name: string;
        childImageSharp: {
          sizes: FluidObject;
        };
      };
    }[];
  };
}

const Image = (props: ImageProps) => {
  const { filename, sizes, alt, ...rest } = props;

  const data = useStaticQuery<Query>(graphql`
    query {
      images: allFile(filter: { extension: { regex: "/(jpeg|jpg|gif|png)/" } }) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              sizes(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  `);

  const image = data.images.edges.find((n: any) => {
    return n.node.relativePath.includes(filename);
  });

  if (!image) return;

  return <Img sizes={sizes || image.node.childImageSharp.sizes} alt={alt} />;
};

export default Image;

// export const fluidImage = graphql`
//   fragment fluidImage on File {
//     childImageSharp {
//       fluid(maxWidth: 1000) {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// `;

// export const fragment = graphql`
//   fragment image on FileConnection {
//     edges {
//       node {
//         id
//         name
//         childImageSharp {
//           fluid(maxHeight: 400) {
//             ...GatsbyImageSharpFluid_withWebp_tracedSVG
//           }
//         }
//       }
//     }
//   }
// `
