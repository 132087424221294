import React from 'react';
import styles from './Button.module.scss';
import { Button as AntdButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';

interface Props extends ButtonProps {}

const Button: React.FC<Props> = props => {
  const { className, ...rest } = props;
  return <AntdButton className={`${styles.btn} ${className}`} {...rest} />;
};

export default Button;
