import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import styles from './PrivacyPolicy.module.scss';

interface Props {}

const PrivacyPolicy: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { Title, Paragraph, Text } = Typography;

  return (
    <div className={`content ${styles.container}`}>
      <Title>プライバシーポリシー</Title>

      <Paragraph>発効日：2020年2月18日</Paragraph>

      <Title level={2}>当社が取得する情報</Title>
      <Paragraph>当社は、基本的に次の３種類の情報を収集します。 </Paragraph>
      <ul>
        <li>
          <strong>お客様が提供する情報</strong>
        </li>
        <li>
          <strong>お客様が当社のサービスを利用する際に取得する情報</strong>
        </li>
        <li>
          <strong>当社が第三者から取得する情報</strong>
        </li>
      </ul>
      <Paragraph>上記の3つの情報収集手段に関し、以下でご説明いたします。</Paragraph>

      <Title level={4}>お客様が提供する情報</Title>
      <div>
        <Paragraph>
          当社では、お客様が当社のサービスをご利用になる際に、お客様が当社に提供された情報を収集します。
          例えば、当社のサービスでは、お客様にアカウントをセットアップすることをお願いしており、
          お名前、ユーザー名、パスワード、メールアドレス、電話番号、誕生日といった個人に関するいくつかの重要な情報を収集することとなります。
          またプロフィール写真やアバターなど、当社のサービス上において公開される追加情報の提供をお客様に求める場合もあります。
          商品販売などのその他のサービスにおいては、デビットカード番号またはクレジットカード番号、
          および関連口座の情報を提供していただく必要がある場合があります。
        </Paragraph>
        <Paragraph>
          チャットなど、当社のサービスを通じてお客様が送信された情報も当社に提供されることになります。
          お客様のチャット、その他のコンテンツを見たユーザーは、そのようなコンテンツを保存したり、アプリ外の場所にコピーすることができますので、ご注意ください。
          インターネット上の一般常識は<span className={styles.appName}>{t('common.appName')}</span>
          にも当てはまります。他のユーザーに保存または共有されることを望まないメッセージやコンテンツは、送信しないよう、お願いいたします。
        </Paragraph>
        <Paragraph>
          お客様が顧客サポートにお問い合わせいただくなど当社にご連絡をとられる場合、当社はお客様から任意にご提供いただいた情報、
          または当社がお客様の疑問を解決するために必要とする情報を収集することとなります。
        </Paragraph>
      </div>

      <Title level={4}>お客様が当社のサービスを利用する際に取得する情報</Title>
      <div>
        <Paragraph>
          お客様が当社のサービスを利用する際、当社ではお客様が利用したサービスの利用方法に関する情報を取得します。
          お客様が当社のサービスを利用した際に取得される情報の詳細な内容は、以下のとおりです。
        </Paragraph>
        <ul>
          <li>
            <strong>利用状況に関する情報</strong>
            ：当社のサービスを通じてお客様が行った操作に関する情報が取得されます。例として、以下に関する情報が取得されることがあります。
            <ul>
              <li>送信した検索クエリなど、当社のサービスをどのように利用したか。</li>
              <li>
                相手の名前、送信日時、フレンドと交換するメッセージ数、最も頻繁にメッセージを交換するフレンド、
                メッセージに対する操作（メッセージの開封やスクリーンショットのキャプチャーなど）など、他の
                <span className={styles.appName}>{t('common.appName')}</span>ユーザーとのやり取り。
              </li>
            </ul>
          </li>
          <li>
            <strong>コンテンツ情報</strong>
            ：当社のサービスを使用してお客様が作成または提供するコンテンツに関する情報（メッセージ、画像、受信者のコンテンツ閲覧の有無、コンテンツと一緒に送信されたメタデータなど）が取得されます。
          </li>
          <li>
            <strong>デバイス情報</strong>
            ：お客様がお使いのデバイスに関する情報や、デバイスから送信される情報を取得します。こうした情報の例には、以下が挙げられます。
            お客様がお使いのハードウェアおよびソフトウェアに関する情報（ハードウェアの機種、オペレーティングシステムのバージョン、
            デバイスのメモリーズ、広告識別子、一意のアプリケーション識別子、インストール済みのアプリ、一意のデバイス識別子、
            使用ブラウザ、使用言語、バッテリー容量、タイムゾーンなど）
            デバイスセンサーに関する情報（加速度センサー、ジャイロスコープ、コンパス、マイク、ヘッドホンの接続の有無など）
            無線及びモバイルネットワークに関する情報（携帯番号、サービスプロバイダー、IPアドレス、回線の強度など）
          </li>
          <li>
            <strong>位置情報</strong>
            ：お客様が当社のサービスを利用する際、お客様の位置に関する情報が取得される場合があります。
            お客様の許可を得て、当社は、GPSや無線ネットワーク、携帯電話基地局、Wi-Fiアクセスポイント、ジャイロスコープ、加速度計、方位磁石、
            その他のセンサーなどを利用し、お客様の正確な位置情報を取得することがあります。
          </li>
          <li>
            <strong>Cookieやその他のテクノロジーによって収集される情報</strong>
            ：多くのオンラインサービスやモバイルアプリと同様に、当社はCookie、ウェブビーコン、ウェブストレージ、広告識別子などのテクノロジーを使用して、
            お客様の行動やブラウザ、デバイスに関する情報を収集する場合があります。
            お客様のお好みにより、ブラウザまたはデバイスの設定でCookieを削除または拒否することもできます。
            ただし、Cookieを削除または拒否すると、当社のサービスを利用することができなくなったり、
            当社のサービスが機能しなくなったりすることがありますので、ご注意ください。
            当社のサービスにおける当社および当社のビジネスパートナーのCookieの使用方法、並びにお客様の選択肢に関する詳細はCookieに関する声明をご確認ください。
          </li>
          <li>
            <strong>ログ情報</strong>
            ：お客様が当社のウェブサイトを利用すると、下記のようなログファイル情報が収集されます。
            <ul>
              <li>お客様が当社のサービスをどのように利用したかに関する詳細</li>
              <li>ウェブブラウザの種類や言語などのデバイス情報</li>
              <li>アクセス時刻</li>
              <li>閲覧したページ</li>
              <li>IPアドレス</li>
              <li>お客様のデバイスまたはブラウザを識別するCookieまたはその他のテクノロジーに関連する識別子、そして</li>
              <li>お客様が当社のウェブサイトを訪れる前後に閲覧していたページ</li>
            </ul>
          </li>
        </ul>
      </div>

      <Title level={4}>当社が第三者から取得する情報</Title>
      <Paragraph>
        また、当社は他のユーザー、関連会社、または第三者からお客様の情報を収集する場合があります。以下はその例です。
      </Paragraph>
      <ul>
        <li>
          <span className={styles.appName}>{t('common.appName')}</span>
          アカウントを別のサービスにリンクすると、そのサービスから利用状況などの情報を受け取る場合があります。
        </li>
        <li>
          広告主、アプリ開発者、出版社、その他の第三者が情報を当社と共有することもあります。当社はこの情報を広告のターゲットを絞ったり、
          広告のパフォーマンスを測定したりするために使用します。
        </li>
        <li>
          別のユーザーが連絡先リストを取り込んだ場合、そのユーザーの連絡先リストを、当社が収集した他のお客様の個人情報と統合する場合があります。
        </li>
      </ul>

      <Title level={2}>当社による情報の利用</Title>
      <Paragraph>
        当社は、お客様にさまざまな製品やサービスを提供し、その改善を図るために、お客様から取得した情報を利用します。情報の利用例は、以下のとおりです。
      </Paragraph>
      <ul>
        <li>製品およびサービスの開発、運用、改善、提供、保守、保護</li>
        <li>
          メールなどのメッセージの送信。例えば、サポートのお問い合わせに対する返信や、お客様の関心があると思われる当社の製品、
          サービス、キャンペーン情報などをお届けする際に、メールを使用する場合など。
        </li>
        <li>利用動向や利用状況のモニタリングと分析</li>
        <li>
          おすすめルームもしくはプロフィール情報、または当社がお客様に提供するコンテンツ（広告を含む）のカスタマイズをはじめとする、
          お客様に対する当社のサービスの個別化。
        </li>
        <li>位置情報（お客様が位置情報の取得を許可した場合）を使用したお客様一人ひとりに合わせた体験の提供</li>
        <li>
          当社のサービスやそれ以外での位置情報（お客様が位置情報の取得を許可した場合）を活用した、ターゲット広告の表示や広告効果の測定の提供と改善。
        </li>
        <li>当社の製品およびサービスの安全性およびセキュリティの強化</li>
        <li>身元の確認や、詐欺または他の不正もしくは違法な行為の防止</li>
        <li>
          Cookieやその他の技術を使用して当社が取得した情報を利用することによる、サービスの強化やユーザーエクスペリエンスの向上
        </li>
        <li>利用規約およびその他の利用ポリシーの実施、そして法的要件の遵守</li>
      </ul>

      <Title level={2}>プライバシーポリシーの改訂</Title>
      <Paragraph>
        本プライバシーポリシーは、随時変更される場合があります。変更がある場合には、何らかの方法により、当社からお客様へお知らせいたします。
        なお、当社のウェブサイトおよびモバイルアプリに表示されるプライバシーポリシーの上部の最終更新日を更新することでお知らせする場合もあり、
        また、ウェブサイトのホームページにお知らせを記載する、またはアプリ内通知によりお知らせを行うなどの方法により追加の通知を行う場合もあります。
      </Paragraph>
    </div>
  );
};

export default PrivacyPolicy;
