import React from 'react';
import './styles.css';

interface Props {
  n?: (1 | 2 | 3 | 4)[];
}

const Clouds: React.FC<Props> = ({ n = [1, 2, 3, 4] }: Props) => {
  return (
    <>
      {n.map(n => (
        <span key={`cloud-${n}`} className={`clouds cloud-${n}`}></span>
      ))}
    </>
  );
};

export default Clouds;
