import React from 'react';
import { animated, useSpring } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import './styles.css';

type AnimationType = 'fadeIn' | 'fadeInLeft' | 'fadeInRight' | 'slideInDown';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  type: AnimationType;
  threshold?: number;
  delay?: number;
  duration?: number;
}

const AnimatedDiv: React.FC<Props> = props => {
  const { children, style, type, threshold, delay, duration, ...rest } = props;
  const [ref, inView] = useInView({ threshold, triggerOnce: true });
  const config = { duration };

  const animatedValue = ((type: AnimationType) => {
    if (type === 'fadeIn') {
      const from = { opacity: 0 };
      return useSpring({
        config,
        from,
        to: inView ? { opacity: 1 } : from,
        delay,
      });
    } else if (type === 'slideInDown') {
      const from = { opacity: 1, marginTop: -600 };
      return useSpring({
        config,
        from,
        to: inView ? { opacity: 1, marginTop: 0 } : from,
        delay,
      });
    } else if (type === 'fadeInLeft') {
      const from = { opacity: 0, marginLeft: -600 };
      return useSpring({
        config,
        from,
        to: inView ? { opacity: 1, marginLeft: 0 } : from,
        delay,
      });
    } else if (type === 'fadeInRight') {
      const from = { opacity: 0, marginRight: -600 };
      return useSpring({
        config,
        from,
        to: inView ? { opacity: 1, marginRight: 0 } : from,
        delay,
      });
    }
  })(type);

  return (
    <animated.div style={{ ...animatedValue, ...style }} {...rest} ref={ref}>
      {children}
    </animated.div>
  );
};

AnimatedDiv.defaultProps = {
  type: 'fadeIn',
  threshold: 0.5,
  delay: 0,
  duration: 600,
};

export default AnimatedDiv;
