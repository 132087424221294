import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Icon } from 'antd';
import styles from './AppFeatures.module.scss';

interface Props {}

const SectionAppFeatures: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div className="content">
          <Row className={styles.appFeatures}>
            <Col md={8} className={styles.appFeature}>
              <Icon type="team" className={`${styles.iconHolder} ${styles.holderBlue} wow fadeIn`} />
              <h2>{t('common.appfeature1')}</h2>
              <p className={styles.description}>{t('common.appfeatureDescription1')}</p>
            </Col>
            <Col md={8} className={styles.appFeature}>
              <Icon type="message" className={`${styles.iconHolder} ${styles.holderYellow} wow fadeIn`} />
              <h2>{t('common.appfeature2')}</h2>
              <p className={styles.description}>{t('common.appfeatureDescription2')}</p>
            </Col>
            <Col md={8} className={styles.appFeature}>
              <Icon type="heart" className={`${styles.iconHolder} ${styles.holderRed} wow fadeIn`} />
              <h2>{t('common.appfeature3')}</h2>
              <p className={styles.description}>{t('common.appfeatureDescription3')}</p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SectionAppFeatures;
