import React from 'react';
import { Location, LocationContext } from '@reach/router';
import queryString from 'query-string';

export interface WithLocationProps<QsParams = {}> extends LocationContext {
  search: QsParams;
}

export const withLocation = <P extends object>(
  Component: React.FC<P & WithLocationProps> | React.ComponentType<P & WithLocationProps>
) => (props: P) => (
  <Location>
    {({ location, navigate }) => (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        search={location.search ? queryString.parse(location.search) : {}}
      />
    )}
  </Location>
);
