import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import styles from './AppStoreButton.module.scss';
import { Images } from '../../../../images';
import { Button } from '../../..';
import config from '../../../../config';

interface Props extends ButtonProps {
  storeType: 'android' | 'ios';
}

const AppStoreButton: React.FC<Props> = props => {
  const { storeType, className, ...rest } = props;

  const navigateToStore = () => {
    const href = storeType == 'android' ? config.GOOGLE_PLAY_URL : config.APP_STORE_URL;
    window.open(href, '_blank');
  };

  return (
    <Button size="large" className={`${styles.btnAppstore} ${className}`} onClick={navigateToStore} {...rest}>
      {storeType == 'android' ? (
        <img src={Images.appstoreAndroid} alt="google play" />
      ) : (
        <img src={Images.appstoreApple} alt="app store" />
      )}
    </Button>
  );
};

export default AppStoreButton;
