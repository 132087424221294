export const ImageFiles: { [key: string]: string } = {
  logo: 'ic-logo-256.png',
  screen1: 'iPhone_6/screen-1.png',
  screen2: 'iPhone_6/screen-2.png',
  screen3: 'iPhone_6/screen-3.png',
  iphone6Flat3: 'iPhone_6/iphone-6-flat-3.png',
  header1: 'patterns/header/pattern-1.png',
  footer3: 'patterns/footer/pattern-3.png',
  appstoreAndroid: 'appstore-android.svg',
  appstoreApple: 'appstore-apple.svg',
};

export const Images = {
  logo: require('./' + ImageFiles.logo),
  screen1: require('./' + ImageFiles.screen1),
  screen2: require('./' + ImageFiles.screen2),
  screen3: require('./' + ImageFiles.screen3),
  iphone6Flat3: require('./' + ImageFiles.iphone6Flat3),
  header1: require('./' + ImageFiles.header1),
  footer3: require('./' + ImageFiles.footer3),
  appstoreAndroid: require('./' + ImageFiles.appstoreAndroid),
  appstoreApple: require('./' + ImageFiles.appstoreApple),
};
