import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from '@reach/router';
import { Button, Card } from 'antd';
import queryString from 'query-string';
import styles from './AuthAction.module.scss';
import { withLocation, WithLocationProps } from '../../../libs';
import { Firebase } from '../../../services/firebase';
import { Image } from '../..';
import { Typography } from 'antd';

interface QsParams {
  mode: 'resetPassword' | 'recoverEmail' | 'verifyEmail';
  oobCode: string;
  continueUrl: string;
}

interface Props {}

const AuthAction: React.FC<Props & WithLocationProps<QsParams>> = ({ search }) => {
  const { t } = useTranslation();
  const [completed, setCompleted] = React.useState(false);
  const [redirectTo, setRedirectTo] = React.useState(null);
  const { continueUrl } = search;
  const { Text } = Typography;

  const checkMode = (search: QsParams): boolean => {
    const { mode } = search;
    if (mode !== 'verifyEmail') {
      const href = `/__/auth/action?${queryString.stringify(search)}`;
      setRedirectTo(href);
      return false;
    }
    return true;
  };

  const verifyEmail = async (search: QsParams) => {
    try {
      const { oobCode } = search;
      const firebase = await Firebase.getInstance();
      const actionCodeInfo = await firebase.auth.checkActionCode(oobCode);
      const { email } = actionCodeInfo.data;
      await firebase.auth.applyActionCode(oobCode);
      await firebase.functions.httpsCallable('verifyEmail')({ email });
      setCompleted(true);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    const result = checkMode(search);
    if (result) {
      verifyEmail(search);
    }
  }, []);

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} />}
      <div className={styles.container}>
        <Card bordered={false} style={{ width: '40vw', minWidth: 600 }}>
          <div className={styles.cardContent}>
            <div className={styles.imageContainer}>
              <Image filename={completed ? 'ic-email-verified.png' : 'ic-email-verifying.png'} />
            </div>
            <div className={styles.descriptionContainer}>
              <Text strong>{completed ? t('auth.verifiedMessage') : t('auth.verifyingMessage')}</Text>
            </div>

            {continueUrl && (
              <div className={styles.btnContainer}>
                <Button
                  size="large"
                  className="btn"
                  onClick={() => continueUrl && window.open(continueUrl, '_blank')}
                  disabled={!completed}
                  loading={!completed}
                >
                  {t('auth.continueToApp')}
                </Button>
              </div>
            )}
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default withLocation<Props>(AuthAction);
