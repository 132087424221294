import React from 'react';
import Slider from 'react-slick';
import { Image } from '../..';
import { ImageFiles } from '../../../images';
import styles from './AppMobileImage.module.scss';

interface Props {}

const AppMobileImage: React.FC<Props> = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    swipeToSlide: false,
    touchMove: false,
  };

  return (
    <div className={`${styles.carouselIphone6} carousel slide wow comeInDown`} data-ride="carousel">
      <div className={styles.carouselInner}>
        <Slider {...settings}>
          <div className={`${styles.item} active`}>
            <div className={styles.carouselContent}>
              <Image filename={ImageFiles.screen1} alt="iphone screenshot" />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.carouselContent}>
              <Image filename={ImageFiles.screen2} alt="iphone screenshot" />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.carouselContent}>
              <Image filename={ImageFiles.screen3} alt="iphone screenshot" />
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default AppMobileImage;
