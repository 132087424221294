import React from 'react';
import { Icon } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import styles from './SocialButton.module.scss';
import { Button } from '../../..';

interface Props extends ButtonProps {
  providerType: 'facebook' | 'twitter';
}

const SocialButton: React.FC<Props> = props => {
  const { providerType, className, ...rest } = props;

  return providerType == 'facebook' ? (
    <Button size="large" className={`${styles.btnFacebook} ${className}`} {...rest}>
      <Icon type="like" theme="filled" />
    </Button>
  ) : (
    <Button size="large" className={`${styles.btnTwitter} ${className}`} {...rest}>
      <Icon type="twitter" />
    </Button>
  );
};

export default SocialButton;
