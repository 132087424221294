import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { AppMobileImage, Clouds, Image, SocialButton, AppStoreButton } from '../../..';
import { ImageFiles } from '../../../../images';
import styles from './Header.module.scss';

interface Props {}

const SectionHeader: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <header className={`${styles.container} ${styles.sectionClouds} parallax`}>
      <div className={`content ${styles.showcaseInner}`}>
        <Row type="flex" justify="center">
          <Col md={8} className={styles.appIntro}>
            <h1 className={`${styles.mainTitle} ${styles.headerTextEffect}  wow fadeInLeft`} data-wow-duration=".5s">
              <div className={styles.logo}>
                <Image filename={ImageFiles.logo} />
              </div>
              {t('common.appName')}
            </h1>
            <h1 className={`${styles.subHeading} wow fadeInLeft`} data-wow-duration=".7s">
              {t('common.tagLine')}
            </h1>
            <p className={`${styles.appDescription} wow fadeInLeft`} data-wow-duration="1s">
              {t('common.appDescription')}
            </p>
          </Col>
          <Col md={8} className={styles.appScreenshots}>
            <AppMobileImage />
          </Col>
          <Col md={8} className={styles.appLinks}>
            <div className="wow fadeInRight" data-wow-duration="1s">
              <div className={styles.appStores}>
                <AppStoreButton storeType="android" />
                <AppStoreButton storeType="ios" />
              </div>
              <div className={styles.socialLinks}>
                <SocialButton providerType="facebook" />
                <SocialButton providerType="twitter" />
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Clouds />
    </header>
  );
};

export default SectionHeader;
